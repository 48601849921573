import * as React from "react";
import { Heading } from "@quandoo-dev/cookbook/lib/typography";
import Grid from "@quandoo-dev/cookbook/lib/grid";
import Layout from "../components/layout";
import { Helmet } from "react-helmet";
import Divider from "@quandoo-dev/cookbook/lib/divider";
import SearchResults from "../components/search/searchResults";
import Breadcrumbs, { Crumb } from "../components/breadcrumbs";
import useTranslation from "../hooks/useTranslation";
import { getOneTrustId } from '../utils/one-trust-ids';

interface BlogProps {
  home: any;
  s: any;
}

const SearchResultsPage: React.FC<BlogProps> = ({ home, s }) => {
  const crumbs = [
    {
      // TODO: the string for "Home" should come from Contentful according to locale
      name: "Home",
      to: `/`,
      item: `${home.domain}`,
    },
    {
      name: s,
      item: `${home.domain}/?s=${s}`,
    } as Crumb,
  ];

  const url = `${home.domain}`;

  const ldJson = {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      { "@type": "ListItem", position: 1, item: { "@id": url, name: "Home" } },
      {
        "@type": "ListItem",
        position: 2,
        item: { "@id": null, name: `You searched for ${s}` },
      },
    ],
  };

  return (
    <Layout locale={home.slug}>
      <Helmet
        htmlAttributes={{
          lang: home.languageCode,
        }}
        title={s + " | " + home.name}
        titleTemplate={s + " | " + home.name}
        link={[
          {
            rel: "canonical",
            key: home.domain,
            href: home.domain,
          },
        ]}
        meta={[
          {
            name: `robots`,
            content: "noindex, nofollow",
          },
          {
            name: `description`,
            content: home.description.description,
          },
          {
            property: `og:title`,
            content: s + " | " + home.name,
          },
          {
            property: `og:url`,
            content: home.domain,
          },
          {
            property: `og:locale`,
            content: home.languageCode,
          },
          {
            property: `og:description`,
            content: home.description.description,
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            property: `og:image`,
            content: home.logo.file.url,
          },
          {
            name: `twitter:card`,
            content: `summary`,
          },
          {
            name: `twitter:creator`,
            content: home.twitterAccountName,
          },
          {
            name: `twitter:title`,
            content: home.name,
          },
          {
            name: `twitter:description`,
            content: home.description.description,
          },
        ]}
      >
        <script type="application/ld+json">{JSON.stringify(ldJson)}</script>
        {home.slug !== 'au' && <script src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"  type="text/javascript" charSet="UTF-8" data-domain-script={getOneTrustId(home.slug)} ></script>}
        {home.slug !== 'au' && <script type="text/javascript">function OptanonWrapper() { }</script>}
      </Helmet>
      <Breadcrumbs crumbs={crumbs} />
      <Grid mx="0" mb="xxxl">
        <Grid.Row>
          <Grid.Item columns={12}>
            <Heading size={6} color="secondary">
              {useTranslation(home.slug, "search.results_for")}
            </Heading>
          </Grid.Item>
        </Grid.Row>
        <Grid.Row mb="md">
          <Grid.Item columns={12}>
            <Heading size={2} color="default" my="md">
              {s}
            </Heading>
          </Grid.Item>
        </Grid.Row>
        <Grid.Row mb="sm">
          <Grid.Item columns={12}>
            <Heading size={6} color="secondary">
              {useTranslation(home.slug, "search.all_results")}
            </Heading>
            <Divider color="#D8D8D8" mt="xxs" />
          </Grid.Item>
        </Grid.Row>
        <Grid.Row>
          <SearchResults query={s} locale={home.slug} />
        </Grid.Row>
      </Grid>
    </Layout>
  );
};

export default SearchResultsPage;
