import * as React from "react";
import Grid from "@quandoo-dev/cookbook/lib/grid";
import Layout from "../components/layout";
import PostInRow from "../components/cards/postInRow";
import PostInColumn from "../components/cards/postInColumn";
import Divider from "@quandoo-dev/cookbook/lib/divider";
import TopArticle from "../components/top-article";
import styled from "styled-components";
import { Box } from "@quandoo-dev/cookbook/lib/layout";
import FeaturedPost from "../components/featured-post/featured-post";
import Helmet from "react-helmet";
import Tags from "../components/post/tags";
import useTranslation from "../hooks/useTranslation";
import { Heading } from "@quandoo-dev/cookbook/lib/typography";
import Newsletter from "../components/post/newsletter";
import { getOneTrustId } from '../utils/one-trust-ids';

interface BlogProps {
  home: any;
  latestPosts: any;
  tags: any;
}

const ArticleH2 = styled.h2`
  font-size: 18px;
  color: #3a4354;
  margin: 0;
  padding: 0;
  font-weight: 600;
  line-height: 40px;
  opacity: 0.9;
`;

const ArticleBorder = styled(Grid.Item)`
  width: 100%;
  padding-top: 0;
  margin: 14px 0 0;
  display: inline-block;
  overflow: hidden;
  section {
    margin: 0;
  }
  div {
    float: left;
    margin-bottom: 5px;
    width: 100%;
  }
  &:nth-child(2) {
    border-bottom: none;
  }
  @media only screen and (min-width: 600px) {
    border-left: 1px solid #D8D8D8;
    height: 142px;
    margin-bottom:6px;
    padding-left: 16px;
    &:nth-child(2) {
      border-left: none;
    }
  }
  @media (max-width: 600px){
    border-top: 1px solid #D8D8D8;
  }
`;

const CustomDivider = styled(Divider)`
  @media (max-width: 900px) {
    display: none;
  }
`;

const HomePage: React.FC<BlogProps> = ({ home, latestPosts, tags }) => {
  const recentArticles = latestPosts.map((post, index) => (
    <ArticleBorder
      columns={[12, 12, 6, 3]}
      key={index}
      maxHeight={["106px", "106px", "auto", "auto"]}
    >
      <PostInRow
        post={post}
        showImage={false}
        staticImage={null}
        hideDivider={true}
      ></PostInRow>
    </ArticleBorder>
  ));

  const rowArticles = home.rowArticles.map((post, index) => (
    <Grid.Item columns={[12, 12, 6, 3]} key={index}>
      <PostInRow post={post} staticImage={null} hideDivider={home.rowArticles.length - 1 == index}></PostInRow>
    </Grid.Item>
  ));

  const leftColumnArticles = home.leftColumnArticles.map((post, index) => (
    <Grid.Item columns={[12, 12, 12, 12]} key={index}>
      <PostInColumn post={post}></PostInColumn>
    </Grid.Item>
  ));

  const rightColumnArticles = home.rightColumnArticles.map((post, index) => (
    <Grid.Item columns={[12, 12, 12, 12]} key={index}>
      <PostInColumn post={post}></PostInColumn>
    </Grid.Item>
  ));

  const ldJson = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    name: home.name,
    description: home.description.description,
    publisher: {
      "@type": "Organization",
      name: home.name,
      sameAs: home.domain,
      logo: { "@type": "ImageObject", url: `https:${home.logo.file.url}` },
    },
    //datePublished: home.updatedAt.substr(0,home.updatedAt.indexOf("T")),
    url: home.domain,
  };

  return (
    <Layout locale={home.slug}>
      <Helmet
        htmlAttributes={{
          lang: home.languageCode,
        }}
        title={home.name}
        titleTemplate={home.name}
        link={[
          {
            rel: "canonical",
            key: home.domain,
            href: home.domain,
          },
        ]}
        meta={[
          {
            name: `robots`,
            content: "index, follow",
          },
          {
            name: `description`,
            content: home.description.description,
          },
          {
            property: `og:title`,
            content: home.name,
          },
          {
            property: `og:url`,
            content: home.domain,
          },
          {
            property: `og:locale`,
            content: home.languageCode,
          },
          {
            property: `og:description`,
            content: home.description.description,
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            property: `og:image`,
            content: home.logo.file.url,
          },
          {
            name: `twitter:card`,
            content: `summary`,
          },
          {
            name: `twitter:creator`,
            content: home.twitterAccountName,
          },
          {
            name: `twitter:title`,
            content: home.name,
          },
          {
            name: `twitter:description`,
            content: home.description.description,
          },
        ]}
      >
        <script type="application/ld+json">{JSON.stringify(ldJson)}</script>
        {home.slug !== 'au' && <script src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"  type="text/javascript" charSet="UTF-8" data-domain-script={getOneTrustId(home.slug)} ></script>}
        {home.slug !== 'au' && <script type="text/javascript">function OptanonWrapper() { }</script>}
      </Helmet>
      <Grid.Row mb="xxxl" mt="xxxl" mx="auto">
        <Grid.Item style={{ width: "100%" }}>
          <TopArticle
            post={home.topArticle}
            image={home.topArticleImage}
            isHomepage={true}
          ></TopArticle>
        </Grid.Item>
      </Grid.Row>
      <Grid.Row mb="xxxl">
        <Grid.Item columns={12}>
          <ArticleH2>
            {useTranslation(home.slug, "home.latest_posts")}
          </ArticleH2>
          <CustomDivider color="#D8D8D8" mt="xxs" />
        </Grid.Item>
        {recentArticles}
        <Grid.Item columns={12}>
          <CustomDivider color="#D8D8D8" mt="xxs" />
        </Grid.Item>
      </Grid.Row>
      <Grid.Row mb="xxxl">
        <Grid.Item columns={12}>
          <ArticleH2>{home.articlesRowTitle}</ArticleH2>
          <Divider color="#D8D8D8" mt="xxs" mb="xs" />
        </Grid.Item>
        {rowArticles}
      </Grid.Row>
      <Grid.Row mb="xxxl">
        <Grid.Item columns={12}>
          <ArticleH2>{home.featureSectionTitle}</ArticleH2>
          <Divider color="#D8D8D8" mb="lg" />
        </Grid.Item>
        <FeaturedPost post={home.featureArticle}></FeaturedPost>
      </Grid.Row>
      <Grid.Row>
        <Grid.Item columns={[12, 12, 6, 6]} mb="xxxl">
          <ArticleH2>{home.leftArticlesColumnTitle}</ArticleH2>
          <Divider color="#D8D8D8" mt="xxs" mb="xs" />
          <Grid style={{ margin: "0" }}>
            <Grid.Row>{leftColumnArticles}</Grid.Row>
          </Grid>
        </Grid.Item>
        <Grid.Item columns={[12, 12, 6, 6]}>
          <ArticleH2>{home.rightArticlesColumnTitle}</ArticleH2>
          <Divider color="#D8D8D8" mt="xxs" mb="xs" />
          <Grid style={{ marginLeft: "0" }}>
            <Grid.Row>{rightColumnArticles}</Grid.Row>
          </Grid>
        </Grid.Item>
      </Grid.Row>
      <Grid.Row>
        <Box maxWidth={{ _: "auto", md: "770px", lg: "800px" }} mx="auto">
          {/* <Newsletter /> */}
        </Box>
        <Grid.Item columns={[12, 12, 12, 12]} mt="xxl" mb="xxl">
          <Heading size={4} fontWeight="semibold">
            {useTranslation(home.slug, "home.popular_tags")}
          </Heading>
          <Tags tags={tags} country={home.slug} showTitle={false} />
        </Grid.Item>
      </Grid.Row>
    </Layout>
  );
};

export default HomePage;
