import * as React from "react";
import Link from "../../components/link";
import Grid from "@quandoo-dev/cookbook/lib/grid";
import { Heading, Body } from "@quandoo-dev/cookbook/lib/typography";
import Img, { GatsbyImageProps } from "gatsby-image";
import { Box } from "@quandoo-dev/cookbook/lib/layout";
import styled from "styled-components";
interface Post {
  title: string;
  category: {
    name: string;
    slug: string;
  };
  locale: {
    slug: string;
  };
  previewImage: {
    description: string;
    asset: GatsbyImageProps;
  };
  slug: string;
  timeToRead: string;
}

interface PostInColumnProps {
  post: Post;
}

const ArticleH3 = styled.h3`
  font-size: 18px;
  color: #3c4656;
  margin: 0;
  padding: 0;
  font-weight: 700;
  line-height: 24px;
`;

const CategoryTime = styled.span`
  font-weight: 700;
  text-transform: uppercase;
  font-size: 12px;
`;

const StyledBox = styled(Box)`
  width: 100%;
  height: 72px;
  @media (min-width: 375px) {
    height: 87px;
  }
  @media (min-width: 600px) {
    height: 96px;
  }
  @media (min-width: 1024px) {
    width: 135px;
    height: 96px;
  }
  @media (min-width: 1260px) {
    width: 176px;
    height: 125px;
  }
`;

const PostInColumn: React.FC<PostInColumnProps> = ({ post }) => {
  return (
    <Grid my="md" style={{ marginLeft: "0", marginRight: "0" }}>
      <Grid.Row>
        <Grid.Item columns={[5,5,5,4]}>
          <Link to={`/${post.category.slug}/${post.slug}`}>
            <StyledBox>
              <Img
                alt={post.previewImage.description}
                fluid={post.previewImage.asset.fluid}
                style={{
                  borderRadius: "8px",
                  marginTop: "6px",
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
            </StyledBox>
          </Link>
        </Grid.Item>
        <Grid.Item columns={[7,7,7,8]}>
          <Link to={`/${post.category.slug}`}>
            <Body>
              <CategoryTime>
                {post.category.name.toUpperCase()} &sdot;{" "}
                {post.timeToRead.toUpperCase()}
              </CategoryTime>
            </Body>
          </Link>
          <Link to={`/${post.category.slug}/${post.slug}`}>
            <ArticleH3>{post.title}</ArticleH3>
          </Link>
        </Grid.Item>
      </Grid.Row>
    </Grid>
  );
};

export default PostInColumn;
