import * as React from "react";
import { graphql } from "gatsby";
import queryString from "query-string";
import HomePage from "./homePage";
import SearchResultsPage from "./searchResultsPage";

interface BlogProps {
  data: any;
  location: { search: string };
}

const BlogLocale: React.FC<BlogProps> = props => {
  const {
    data: {
      contentfulLocale: home,
      allContentfulPost: { nodes: latestPosts },
      allContentfulTag: { nodes: tags },
    },
    location: { search: search },
  } = props;

  const { s } = search ? queryString.parse(search) : { s: null };

  if (s) {
    return <SearchResultsPage home={home} s={s}/>;
  } else {
    return <HomePage home={home} latestPosts={latestPosts} tags={tags} />;
  }
};

export const query = graphql`
  fragment postFields on ContentfulPost {
    slug
    title
    previewImage {
      description
      asset {
        fluid(maxWidth: 536) {
          ...GatsbyContentfulFluid_noBase64
        }
      }
    }
    featureImage {
      description
      asset {
        fluid(maxWidth: 536) {
          ...GatsbyContentfulFluid_noBase64
        }
      }
    }
    description {
      description
    }
    category {
      name
      slug
    }
    locale {
      domain
      slug
    }
    timeToRead
  }
  query($slug: String!) {
    contentfulLocale(slug: { eq: $slug }) {
      updatedAt
      name
      twitterAccountName
      description {
        description
      }
      slug
      domain
      languageCode
      logo {
        file {
          url
        }
      }
      topArticle {
        ...postFields
      }
      topArticleImage {
        description
        asset {
          fluid(maxWidth: 698) {
            ...GatsbyContentfulFluid_noBase64
          }
        }
      }
      articlesRowTitle
      rowArticles {
        ...postFields
      }
      leftArticlesColumnTitle
      leftColumnArticles {
        ...postFields
      }
      rightArticlesColumnTitle
      rightColumnArticles {
        ...postFields
      }
      featureSectionTitle
      featureArticle {
        ...postFields
      }
    }
    allContentfulTag(filter: { locale: { slug: { eq: $slug } } }, limit: 8) {
      nodes {
        name
        slug
        locale {
          slug
        }
      }
    }
    allContentfulPost(
      sort: { fields: [publishDate], order: DESC }
      limit: 4
      filter: { locale: { slug: { eq: $slug } } }
    ) {
      nodes {
        ...postFields
      }
    }
  }
`;

export default BlogLocale;


/*fluid(maxWidth: 613) {
  aspectRatio
  src
  srcSet
  sizes
  ...GatsbyContentfulFluid_noBase64
}
traced: sizes(maxWidth: 614) {
  ...GatsbyContentfulSizes_tracedSVG
}*/