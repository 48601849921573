import * as React from "react";
import { Heading, Body } from "@quandoo-dev/cookbook/lib/typography";
import Link from "../link";
import FeatureImage from "../post/feature-image";
import { GatsbyImageProps } from "gatsby-image";
import Grid from "@quandoo-dev/cookbook/lib/grid";
import { Box, Flex } from "@quandoo-dev/cookbook/lib/layout";
import styled from "styled-components";

const StyledHeading = styled(Heading)`
  font-size: 36px;
  line-height: 44px;
  font-weight: 600;
  @media (max-width: 600px) {
    font-size: 24px;
    line-height: 28px;
  }
`;

interface Post {
  title: string;
  category: {
    name: string;
    slug: string;
  };
  locale: {
    slug: string;
  };
  featureImage: {
    description: string;
    asset: GatsbyImageProps;
  };
  description: {
    description: string;
  };
  slug: string;
}

interface FeatProps {
  post: Post;
}

const FeaturedPost: React.FC<FeatProps> = ({ post }) => {
  return (
    <Grid.Item>
      <Box
        style={{
          width: "100%",
          border: "1px solid #F0F2F5",
          borderRadius: "8px",
        }}
        pt="xl"
        px={{ _: "", md: "", lg: "xl" }}
        pb="xl"
      >
        <Link to={`/${post.category.slug}/${post.slug}`}>
          <Grid>
            <Box display={["block", "block", "none", "none"]}>
              <Grid.Row>
                <Grid.Item columns={[12, 12, 6, 6]}>
                  <Box mb="xxl">
                    <FeatureImage
                      alt={post.featureImage.description}
                      fluid={post.featureImage.asset.fluid}
                    />
                  </Box>
                </Grid.Item>
                <Grid.Item
                  columns={[12, 12, 6, 6]}
                  style={{ color: "rgba(58,67,84,0.9)" }}
                >
                  <StyledHeading size={2} mb="md">
                    {post.title}
                  </StyledHeading>
                  {post.description.description}
                </Grid.Item>
              </Grid.Row>
            </Box>
            <Box display={["none", "none", "block", "block"]} px="50px">
              <Grid.Row>
                <Grid.Item
                  columns={[12, 12, 6, 6]}
                  style={{ color: "rgba(58,67,84,0.9)" }}
                >
                  <StyledHeading size={2} mb="md">
                    {post.title}
                  </StyledHeading>
                  {post.description.description}
                </Grid.Item>
                <Grid.Item columns={[12, 12, 6, 6]}>
                  <Box px="40px">
                    <FeatureImage
                      alt={post.featureImage.description}
                      fluid={post.featureImage.asset.fluid}
                    />
                  </Box>
                </Grid.Item>
              </Grid.Row>
            </Box>
          </Grid>
        </Link>
      </Box>
    </Grid.Item>
  );
};

export default FeaturedPost;
