import * as React from "react";
import { Heading, Body } from "@quandoo-dev/cookbook/lib/typography";
import Link from "../link";
import FeatureImage from "../post/feature-image";
import { GatsbyImageProps } from "gatsby-image";
import Grid from "@quandoo-dev/cookbook/lib/grid";
import styled from "styled-components";

interface Post {
  title: string;
  category: {
    name: string;
    slug: string;
  };
  locale: {
    slug: string;
  };
  previewImage: {
    description: string;
    asset: GatsbyImageProps;
  };
  description: {
    description: string;
  };
  slug: string;
  timeToRead: string;
}

interface TopProps {
  post: Post;
  image: {
    description: string;
    asset: GatsbyImageProps;
  };
  isHomepage?: Boolean;
}

const StyledHeading = styled(Heading)`
  font-weight: 600;
  color: #2d384d;
  font-size: 28px;
  line-height: 32px;
  margin: 10px 0 14px 0;
  @media (min-width: 600px) {
    font-size: 36px;
    line-height: 40px;
  }
  @media (min-width: 1240px) {
    font-size: 45px;
    line-height: 56px;
  }
`;

const CategoryTime = styled.span`
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  text-transform: uppercase;
  margin-bottom: 10px;
  color: #3a4354;
`;

const StyledFeatureImage = styled(FeatureImage)`
  width: 100%;
  height: 165px;
  object-fit: cover;
  @media (min-width: 375px) {
    height: 271px;
  }
  @media (min-width: 1024px) {
    width: 530px;
    height: 268px;
  }
  @media (min-width: 1260px) {
    width: 698px;
    height: 353px;
  }
`;

const TopArticle: React.FC<TopProps> = ({ post, image, isHomepage }) => {
  const homepageTitle = isHomepage ? (
    <div>
      <StyledHeading size={1}>{post.title}</StyledHeading>
    </div>
  ) : (
    <div>
      <StyledHeading size={2} mb="md">
        {post.title}
      </StyledHeading>
    </div>
  );

  return (
    // TODO: transform in reusable component
    //reading time need to be added
    <Link to={`/${post.category.slug}/${post.slug}`}>
      <Grid.Row alignItems="center">
        <Grid.Item columns={[12, 12, 12, 7]}>
          <StyledFeatureImage
            alt={image.description}
            fluid={image.asset.fluid}
            style={{ borderRadius: "8px", marginBottom: "24px" }}
          />
        </Grid.Item>
        <Grid.Item columns={[12, 12, 12, 5]}>
          <CategoryTime>
            {post.category.name} &sdot; {post.timeToRead}
          </CategoryTime>
          {homepageTitle}
          <Body size={["lg", "lg", "lg", "xl"]}>
            {post.description.description}
          </Body>
        </Grid.Item>
      </Grid.Row>
    </Link>
  );
};

TopArticle.defaultProps = {
  isHomepage: true,
};

export default TopArticle;
