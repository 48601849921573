import * as React from "react";

import Tag from "@quandoo-dev/cookbook/lib/tag";
import Divider from "@quandoo-dev/cookbook/lib/divider";
import { Box, Flex } from "@quandoo-dev/cookbook/lib/layout";
import { Heading, Body } from "@quandoo-dev/cookbook/lib/typography";
import styled from "styled-components";
import { themeGet as $ } from "@styled-system/theme-get";
import useTranslation from "../../hooks/useTranslation";

interface ITag {
  name: string;
  locale: { slug: string };
  slug: string;
  showTitle?: Boolean;
}

interface TagsProps {
  tags: ITag[];
  showTitle?: Boolean;
  country: string;
}

const List = styled(Flex)`
  list-style: none;
  flex-wrap: wrap;

  li {
    margin-right: ${$("space.xs")};
    margin-bottom: ${$("space.xs")};
  }
`;

const Tags: React.FC<TagsProps> = ({ tags, showTitle, country }) => {
  const taggedInText = useTranslation(country, "post.tagged_in");
  const textTitle = showTitle ? (
    <Heading size={4} fontWeight="semibold">
      {taggedInText}
    </Heading>
  ) : null;

  return (
    <Box style={{ width: "100%" }}>
      {textTitle}
      <Divider mt="xs" color="#D8D8D8" />
      <List as="ul" p="0">
        {tags.map(({ name, locale, slug }, i) => (
          <li key={i}>
            <Tag as="a" href={`/tag/${slug}`}>
              <Body m="xxs" fontWeight="semibold" size="sm">
                {name}
              </Body>
            </Tag>
          </li>
        ))}
      </List>
    </Box>
  );
};

Tags.defaultProps = {
  showTitle: true,
};

export default Tags;
