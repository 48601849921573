import * as React from "react";
import { graphql, StaticQuery } from "gatsby";
import { Index } from "elasticlunr";
import PostInRow from "../cards/postInRow";
import Grid from "@quandoo-dev/cookbook/lib/grid";

interface SearchResultsProps {
  query: any;
  locale: string;
}

const SearchResults: React.FC<SearchResultsProps> = ({ query, locale }) => {
  return (
    <StaticQuery
      query={graphql`
        query SearchIndexQuery {
          siteSearchIndex {
            index
          }
        }
      `}
      render={data => {
        const index = Index.load(data.siteSearchIndex.index);
        const results = index
          .search(query, { expand: true })
          // Map over each ID and return the full document
          .map(({ ref }) => index.documentStore.getDoc(ref));
        const postsInRows = results.map((result, index) => {
          if (result.locale.slug == locale) {
            return (
              <Grid.Item columns={[12, 12, 6, 3]} key={index}>
                <PostInRow
                  post={result}
                  staticImage={result.previewImage.asset.file.url + "?w=536"}
                  showImage={true}
                  hideDivider={results.length - 1 == index}
                />
              </Grid.Item>
            );
          }
        });
        return postsInRows;
      }}
    />
  );
};

export default SearchResults;
