import * as React from "react";
import Img, { GatsbyImageProps } from "gatsby-image";
import { Box } from "@quandoo-dev/cookbook/lib/layout";

const FeatureImage: React.FC<GatsbyImageProps> = props => {
  return (
    <Box>
      <Img {...props} />
    </Box>
  );
};

export default FeatureImage;
